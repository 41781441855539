import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import { Link } from "react-scroll";

import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.closeNavbar = this.closeNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    closeNavbar() {
        this.setState({
            collapsed: true
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-lg navbar-toggleable-lg" fixed="top" dark>
                    <Container>
                        <NavbarBrand className="nav-brand" tag={Link} to="intro" offset={-45} smooth={true} duration={500} onClick={this.closeNavbar}>
                            <span className="full-text">
                                Vedvei Charolais Ranch
                            </span>
                            <span className="short-text">
                                VCR
                            </span>
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-lg-inline-flex flex-lg-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <li className="nav-item">
                                    <Link
                                        activeClass="active"
                                        to="intro"
                                        spy={true}
                                        smooth={true}
                                        offset={-45}
                                        duration={500}
                                        onClick={this.closeNavbar}
                                    >
                                        HOME
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        activeClass="active"
                                        to="about-vcr"
                                        spy={true}
                                        smooth={true}
                                        offset={-45}
                                        duration={500}
                                        onClick={this.closeNavbar}
                                    >
                                        ABOUT
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        activeClass="active"
                                        to="herd-sires"
                                        spy={true}
                                        smooth={true}
                                        offset={-45}
                                        duration={500}
                                        onClick={this.closeNavbar}
                                    >
                                        HERD SIRES
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        activeClass="active"
                                        to="bulls-for-sale"
                                        spy={true}
                                        smooth={true}
                                        offset={-45}
                                        duration={500}
                                        onClick={this.closeNavbar}
                                    >
                                        BULLS FOR SALE
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        activeClass="active"
                                        to="links"
                                        spy={true}
                                        smooth={true}
                                        offset={-45}
                                        duration={500}
                                        onClick={this.closeNavbar}
                                    >
                                        LINKS
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        activeClass="active"
                                        to="contact-info"
                                        spy={true}
                                        smooth={true}
                                        offset={-45}
                                        duration={500}
                                        onClick={this.closeNavbar}
                                    >
                                        CONTACT INFO
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link
                                        activeClass="active"
                                        to="media"
                                        spy={true}
                                        smooth={true}
                                        offset={-45}
                                        duration={500}
                                        onClick={this.closeNavbar}
                                    >
                                        MEDIA
                                    </Link>
                                </li>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
